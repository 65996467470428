body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-weight: 600;
  margin-bottom: 7px;
  font-size: 17px;
}

Button {
  text-transform: capitalize !important;
}

.cancel_btn {
  color: black !important;
  border: 1px solid black !important;
  background: white;
  border-radius: 4px;
}

.btn_primary {
  text-decoration: none !important;
  background:#4539ca!important;
  color: white !important;
}

.btn_primary:hover {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {

  background: #015249;
}

.relative{
  position: relative;
}

.custom-btn{
  background: #534ba8!important;
  color: #ffffff!important;
  border: 1px solid #534ba8!important;
  border-radius: 5px;
}

.bg-main{
  background: #534ba8;
}

.over-flow-hide-x{
  overflow-x: hidden;
}

.pointer{
  cursor: pointer;
}

.round-border{
  border-radius: 100%;
}

.text-gray{
  color: gray;
}
.img-btn{
  background: #f1f1f1!important;
  color: white!important;
  padding: 15px 8px!important;
}
.img-style{
  width: 100%;
  height: 91px;
  border-radius:5px ;
}

/* sagment style */

.close-btn-position{
  position: absolute;
  right: 2px;
  cursor: pointer;
}

/* Brand style */

.select-style{
  height: 38px;
}
.select-style1{
  height: 50px;
  /* padding-top: -5px; */
  /* padding-bottom: 5px; */
}


/* Model Style */

.w-5{
  width: 5%;
}
.w-12{
  width: 35px;
}

.text_cap{
  text-transform: capitalize!important;
}

/* upload Icon box style */

.box_style{
  width:100px!important;
  height:100px!important;
}
.navLink{
  text-decoration: none;
  width: 100% !important;
color:   rgb(239, 75, 116)
}

.active{
  color: rgb(1, 48, 134) !important;
}
 .not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found {
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.not-found h1 {
  font-size: 3em;
  color: #f00;
  margin: 0;
}

.not-found p {
  font-size: 1.5em;
}

.not-found h1 {
  animation: shake 0.5s;
}


.MuiTablePagination-selectLabel {
  margin:0px;
}
.MuiTablePagination-displayedRows{
  margin:0px;
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
} 



