.main_sec {
  height: 100vh;
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
}

.left {
  width: 50%;
  background-color: #fdf5f7 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

button:hover{
  background-color:#f3648e ;
}
input[type=text]:focus {
  /* background-color: pink; */
  border:pink !important;
  box-shadow: 0 2px 4px 0 pink !important;
}

.right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.inner {
  width: 60%;
}

.main-img {
  size: cover;
}

@media only screen and (max-width: 700px) {
  .main_sec {
    display: block;
  }

  .left {
    /* height: 40vh; */
    display: none;
  }
  .right {
    width: 100%;
  }

  .inner {
    width: 80%;
  }
}
