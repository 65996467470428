.sidebar_main_con {
    width: 230px;
    transition: all 0.3s ease-out;
    background-color: #fdf5f7;
    box-shadow: 0px 0px 10px rgb(198, 198, 198);
   
}

.top_bar {
    height: 8vh;
    background-color: rgb(250, 251, 252);
    color: #fdfdfd;
    box-shadow: 5px 5px 10px rgb(234, 232, 232);
    width: 100%;
    padding-right: 40px !important;
}

.sidebar_item_con {
    margin-top: 15px;

}


.child_con {
    height: 92vh;
    width: 100%;
    overflow-y: scroll;
}


.font_of_logout{
    font-size: 22px;
}
